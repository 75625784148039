import { setContent, keyEnter, toEventWithLocalDates, toViewWithLocalDates, task, createEventClasses, createEventContent, addDay, cloneDate, bgEvent, eventIntersects, createEventChunk, sortEventChunks, datesEqual, toISOString, setPayload, btnTextDay, themeView, btnTextWeek, btnTextMonth, btnTextYear, intl } from '@event-calendar/core';
import { SvelteComponent, init, safe_not_equal, element, space, attr, insert, append, action_destroyer, listen, is_function, noop, detach, run_all, component_subscribe, binding_callbacks, empty, transition_in, group_outros, transition_out, check_outros, ensure_array_like, update_keyed_each, outro_and_destroy_block, create_component, mount_component, destroy_component, destroy_each } from 'svelte/internal';
import { getContext, onMount, afterUpdate } from 'svelte';

/* packages/list/src/Event.svelte generated by Svelte v4.2.18 */

function create_fragment$3(ctx) {
	let article;
	let div0;
	let div0_class_value;
	let t;
	let div1;
	let div1_class_value;
	let setContent_action;
	let article_role_value;
	let article_tabindex_value;
	let mounted;
	let dispose;

	return {
		c() {
			article = element("article");
			div0 = element("div");
			t = space();
			div1 = element("div");
			attr(div0, "class", div0_class_value = /*$theme*/ ctx[0].eventTag);
			attr(div0, "style", /*style*/ ctx[3]);
			attr(div1, "class", div1_class_value = /*$theme*/ ctx[0].eventBody);
			attr(article, "class", /*classes*/ ctx[2]);
			attr(article, "role", article_role_value = /*onclick*/ ctx[5] ? 'button' : undefined);
			attr(article, "tabindex", article_tabindex_value = /*onclick*/ ctx[5] ? 0 : undefined);
		},
		m(target, anchor) {
			insert(target, article, anchor);
			append(article, div0);
			append(article, t);
			append(article, div1);
			/*article_binding*/ ctx[40](article);

			if (!mounted) {
				dispose = [
					action_destroyer(setContent_action = setContent.call(null, div1, /*content*/ ctx[4])),
					listen(article, "click", function () {
						if (is_function(/*onclick*/ ctx[5])) /*onclick*/ ctx[5].apply(this, arguments);
					}),
					listen(article, "keydown", function () {
						if (is_function(/*onclick*/ ctx[5] && keyEnter(/*onclick*/ ctx[5]))) (/*onclick*/ ctx[5] && keyEnter(/*onclick*/ ctx[5])).apply(this, arguments);
					}),
					listen(article, "mouseenter", function () {
						if (is_function(/*createHandler*/ ctx[26](/*$eventMouseEnter*/ ctx[6]))) /*createHandler*/ ctx[26](/*$eventMouseEnter*/ ctx[6]).apply(this, arguments);
					}),
					listen(article, "mouseleave", function () {
						if (is_function(/*createHandler*/ ctx[26](/*$eventMouseLeave*/ ctx[7]))) /*createHandler*/ ctx[26](/*$eventMouseLeave*/ ctx[7]).apply(this, arguments);
					}),
					listen(article, "pointerdown", function () {
						if (is_function(/*$_interaction*/ ctx[8].action?.noAction)) /*$_interaction*/ ctx[8].action?.noAction.apply(this, arguments);
					})
				];

				mounted = true;
			}
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;

			if (dirty[0] & /*$theme*/ 1 && div0_class_value !== (div0_class_value = /*$theme*/ ctx[0].eventTag)) {
				attr(div0, "class", div0_class_value);
			}

			if (dirty[0] & /*style*/ 8) {
				attr(div0, "style", /*style*/ ctx[3]);
			}

			if (dirty[0] & /*$theme*/ 1 && div1_class_value !== (div1_class_value = /*$theme*/ ctx[0].eventBody)) {
				attr(div1, "class", div1_class_value);
			}

			if (setContent_action && is_function(setContent_action.update) && dirty[0] & /*content*/ 16) setContent_action.update.call(null, /*content*/ ctx[4]);

			if (dirty[0] & /*classes*/ 4) {
				attr(article, "class", /*classes*/ ctx[2]);
			}

			if (dirty[0] & /*onclick*/ 32 && article_role_value !== (article_role_value = /*onclick*/ ctx[5] ? 'button' : undefined)) {
				attr(article, "role", article_role_value);
			}

			if (dirty[0] & /*onclick*/ 32 && article_tabindex_value !== (article_tabindex_value = /*onclick*/ ctx[5] ? 0 : undefined)) {
				attr(article, "tabindex", article_tabindex_value);
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) {
				detach(article);
			}

			/*article_binding*/ ctx[40](null);
			mounted = false;
			run_all(dispose);
		}
	};
}

function instance$2($$self, $$props, $$invalidate) {
	let $eventClick;
	let $_view;
	let $eventAllUpdated;
	let $eventDidMount;
	let $_intlEventTime;
	let $theme;
	let $eventContent;
	let $displayEventEnd;
	let $eventClassNames;
	let $eventTextColor;
	let $_resTxtColor;
	let $eventColor;
	let $eventBackgroundColor;
	let $_resBgColor;
	let $eventMouseEnter;
	let $eventMouseLeave;
	let $_interaction;
	let { chunk } = $$props;
	let { displayEventEnd, eventAllUpdated, eventBackgroundColor, eventTextColor, eventColor, eventContent, eventClassNames, eventClick, eventDidMount, eventMouseEnter, eventMouseLeave, theme, _view, _intlEventTime, _resBgColor, _resTxtColor, _interaction, _tasks } = getContext('state');
	component_subscribe($$self, displayEventEnd, value => $$invalidate(33, $displayEventEnd = value));
	component_subscribe($$self, eventAllUpdated, value => $$invalidate(42, $eventAllUpdated = value));
	component_subscribe($$self, eventBackgroundColor, value => $$invalidate(38, $eventBackgroundColor = value));
	component_subscribe($$self, eventTextColor, value => $$invalidate(35, $eventTextColor = value));
	component_subscribe($$self, eventColor, value => $$invalidate(37, $eventColor = value));
	component_subscribe($$self, eventContent, value => $$invalidate(32, $eventContent = value));
	component_subscribe($$self, eventClassNames, value => $$invalidate(34, $eventClassNames = value));
	component_subscribe($$self, eventClick, value => $$invalidate(29, $eventClick = value));
	component_subscribe($$self, eventDidMount, value => $$invalidate(43, $eventDidMount = value));
	component_subscribe($$self, eventMouseEnter, value => $$invalidate(6, $eventMouseEnter = value));
	component_subscribe($$self, eventMouseLeave, value => $$invalidate(7, $eventMouseLeave = value));
	component_subscribe($$self, theme, value => $$invalidate(0, $theme = value));
	component_subscribe($$self, _view, value => $$invalidate(30, $_view = value));
	component_subscribe($$self, _intlEventTime, value => $$invalidate(31, $_intlEventTime = value));
	component_subscribe($$self, _resBgColor, value => $$invalidate(39, $_resBgColor = value));
	component_subscribe($$self, _resTxtColor, value => $$invalidate(36, $_resTxtColor = value));
	component_subscribe($$self, _interaction, value => $$invalidate(8, $_interaction = value));
	let el;
	let event;
	let classes;
	let style;
	let content;
	let timeText;
	let onclick;

	onMount(() => {
		if (is_function($eventDidMount)) {
			$eventDidMount({
				event: toEventWithLocalDates(event),
				timeText,
				el,
				view: toViewWithLocalDates($_view)
			});
		}
	});

	afterUpdate(() => {
		if (is_function($eventAllUpdated)) {
			task(() => $eventAllUpdated({ view: toViewWithLocalDates($_view) }), 'eau', _tasks);
		}
	});

	function createHandler(fn) {
		return is_function(fn)
		? jsEvent => fn({
				event: toEventWithLocalDates(event),
				el,
				jsEvent,
				view: toViewWithLocalDates($_view)
			})
		: undefined;
	}

	function article_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			el = $$value;
			$$invalidate(1, el);
		});
	}

	$$self.$$set = $$props => {
		if ('chunk' in $$props) $$invalidate(27, chunk = $$props.chunk);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty[0] & /*chunk*/ 134217728) {
			$$invalidate(28, event = chunk.event);
		}

		if ($$self.$$.dirty[0] & /*event, style, $theme, $_view*/ 1342177289 | $$self.$$.dirty[1] & /*$_resBgColor, $eventBackgroundColor, $eventColor, $_resTxtColor, $eventTextColor, $eventClassNames*/ 504) {
			{
				// Class & Style
				$$invalidate(3, style = '');

				let bgColor = event.backgroundColor || $_resBgColor(event) || $eventBackgroundColor || $eventColor;

				if (bgColor) {
					$$invalidate(3, style = `background-color:${bgColor};`);
				}

				let txtColor = event.textColor || $_resTxtColor(event) || $eventTextColor;

				if (txtColor) {
					$$invalidate(3, style += `color:${txtColor};`);
				}

				$$invalidate(2, classes = [$theme.event, ...createEventClasses($eventClassNames, event, $_view)].join(' '));
			}
		}

		if ($$self.$$.dirty[0] & /*chunk, $theme, $_view*/ 1207959553 | $$self.$$.dirty[1] & /*$displayEventEnd, $eventContent, $_intlEventTime*/ 7) {
			{
				// Content
				$$invalidate(4, [timeText, content] = createEventContent(chunk, $displayEventEnd, $eventContent, $theme, $_intlEventTime, $_view), content);
			}
		}

		if ($$self.$$.dirty[0] & /*$eventClick*/ 536870912) {
			// Onclick handler
			$$invalidate(5, onclick = createHandler($eventClick));
		}
	};

	return [
		$theme,
		el,
		classes,
		style,
		content,
		onclick,
		$eventMouseEnter,
		$eventMouseLeave,
		$_interaction,
		displayEventEnd,
		eventAllUpdated,
		eventBackgroundColor,
		eventTextColor,
		eventColor,
		eventContent,
		eventClassNames,
		eventClick,
		eventDidMount,
		eventMouseEnter,
		eventMouseLeave,
		theme,
		_view,
		_intlEventTime,
		_resBgColor,
		_resTxtColor,
		_interaction,
		createHandler,
		chunk,
		event,
		$eventClick,
		$_view,
		$_intlEventTime,
		$eventContent,
		$displayEventEnd,
		$eventClassNames,
		$eventTextColor,
		$_resTxtColor,
		$eventColor,
		$eventBackgroundColor,
		$_resBgColor,
		article_binding
	];
}

class Event extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance$2, create_fragment$3, safe_not_equal, { chunk: 27 }, null, [-1, -1]);
	}
}

/* packages/list/src/Day.svelte generated by Svelte v4.2.18 */

function get_each_context$1(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[21] = list[i];
	return child_ctx;
}

// (49:0) {#if chunks.length}
function create_if_block$1(ctx) {
	let div;
	let h4;
	let time0;
	let setContent_action;
	let t0;
	let time1;
	let time1_class_value;
	let setContent_action_1;
	let h4_class_value;
	let t1;
	let each_blocks = [];
	let each_1_lookup = new Map();
	let div_class_value;
	let current;
	let mounted;
	let dispose;
	let each_value = ensure_array_like(/*chunks*/ ctx[2]);
	const get_key = ctx => /*chunk*/ ctx[21].event;

	for (let i = 0; i < each_value.length; i += 1) {
		let child_ctx = get_each_context$1(ctx, each_value, i);
		let key = get_key(child_ctx);
		each_1_lookup.set(key, each_blocks[i] = create_each_block$1(key, child_ctx));
	}

	return {
		c() {
			div = element("div");
			h4 = element("h4");
			time0 = element("time");
			t0 = space();
			time1 = element("time");
			t1 = space();

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			attr(time0, "datetime", /*datetime*/ ctx[5]);
			attr(time1, "class", time1_class_value = /*$theme*/ ctx[6].daySide);
			attr(time1, "datetime", /*datetime*/ ctx[5]);
			attr(h4, "class", h4_class_value = /*$theme*/ ctx[6].dayHead);

			attr(div, "class", div_class_value = "" + (/*$theme*/ ctx[6].day + " " + /*$theme*/ ctx[6].weekdays?.[/*date*/ ctx[0].getUTCDay()] + (/*isToday*/ ctx[3] ? ' ' + /*$theme*/ ctx[6].today : '') + (/*highlight*/ ctx[4]
			? ' ' + /*$theme*/ ctx[6].highlight
			: '')));

			attr(div, "role", "listitem");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, h4);
			append(h4, time0);
			append(h4, t0);
			append(h4, time1);
			append(div, t1);

			for (let i = 0; i < each_blocks.length; i += 1) {
				if (each_blocks[i]) {
					each_blocks[i].m(div, null);
				}
			}

			/*div_binding*/ ctx[20](div);
			current = true;

			if (!mounted) {
				dispose = [
					action_destroyer(setContent_action = setContent.call(null, time0, /*$_intlListDay*/ ctx[8].format(/*date*/ ctx[0]))),
					action_destroyer(setContent_action_1 = setContent.call(null, time1, /*$_intlListDaySide*/ ctx[9].format(/*date*/ ctx[0]))),
					listen(div, "pointerdown", function () {
						if (is_function(/*$_interaction*/ ctx[7].action?.select)) /*$_interaction*/ ctx[7].action?.select.apply(this, arguments);
					})
				];

				mounted = true;
			}
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;

			if (!current || dirty & /*datetime*/ 32) {
				attr(time0, "datetime", /*datetime*/ ctx[5]);
			}

			if (setContent_action && is_function(setContent_action.update) && dirty & /*$_intlListDay, date*/ 257) setContent_action.update.call(null, /*$_intlListDay*/ ctx[8].format(/*date*/ ctx[0]));

			if (!current || dirty & /*$theme*/ 64 && time1_class_value !== (time1_class_value = /*$theme*/ ctx[6].daySide)) {
				attr(time1, "class", time1_class_value);
			}

			if (!current || dirty & /*datetime*/ 32) {
				attr(time1, "datetime", /*datetime*/ ctx[5]);
			}

			if (setContent_action_1 && is_function(setContent_action_1.update) && dirty & /*$_intlListDaySide, date*/ 513) setContent_action_1.update.call(null, /*$_intlListDaySide*/ ctx[9].format(/*date*/ ctx[0]));

			if (!current || dirty & /*$theme*/ 64 && h4_class_value !== (h4_class_value = /*$theme*/ ctx[6].dayHead)) {
				attr(h4, "class", h4_class_value);
			}

			if (dirty & /*chunks*/ 4) {
				each_value = ensure_array_like(/*chunks*/ ctx[2]);
				group_outros();
				each_blocks = update_keyed_each(each_blocks, dirty, get_key, 1, ctx, each_value, each_1_lookup, div, outro_and_destroy_block, create_each_block$1, null, get_each_context$1);
				check_outros();
			}

			if (!current || dirty & /*$theme, date, isToday, highlight*/ 89 && div_class_value !== (div_class_value = "" + (/*$theme*/ ctx[6].day + " " + /*$theme*/ ctx[6].weekdays?.[/*date*/ ctx[0].getUTCDay()] + (/*isToday*/ ctx[3] ? ' ' + /*$theme*/ ctx[6].today : '') + (/*highlight*/ ctx[4]
			? ' ' + /*$theme*/ ctx[6].highlight
			: '')))) {
				attr(div, "class", div_class_value);
			}
		},
		i(local) {
			if (current) return;

			for (let i = 0; i < each_value.length; i += 1) {
				transition_in(each_blocks[i]);
			}

			current = true;
		},
		o(local) {
			for (let i = 0; i < each_blocks.length; i += 1) {
				transition_out(each_blocks[i]);
			}

			current = false;
		},
		d(detaching) {
			if (detaching) {
				detach(div);
			}

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].d();
			}

			/*div_binding*/ ctx[20](null);
			mounted = false;
			run_all(dispose);
		}
	};
}

// (60:8) {#each chunks as chunk (chunk.event)}
function create_each_block$1(key_1, ctx) {
	let first;
	let event;
	let current;
	event = new Event({ props: { chunk: /*chunk*/ ctx[21] } });

	return {
		key: key_1,
		first: null,
		c() {
			first = empty();
			create_component(event.$$.fragment);
			this.first = first;
		},
		m(target, anchor) {
			insert(target, first, anchor);
			mount_component(event, target, anchor);
			current = true;
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;
			const event_changes = {};
			if (dirty & /*chunks*/ 4) event_changes.chunk = /*chunk*/ ctx[21];
			event.$set(event_changes);
		},
		i(local) {
			if (current) return;
			transition_in(event.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(event.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) {
				detach(first);
			}

			destroy_component(event, detaching);
		}
	};
}

function create_fragment$2(ctx) {
	let if_block_anchor;
	let current;
	let if_block = /*chunks*/ ctx[2].length && create_if_block$1(ctx);

	return {
		c() {
			if (if_block) if_block.c();
			if_block_anchor = empty();
		},
		m(target, anchor) {
			if (if_block) if_block.m(target, anchor);
			insert(target, if_block_anchor, anchor);
			current = true;
		},
		p(ctx, [dirty]) {
			if (/*chunks*/ ctx[2].length) {
				if (if_block) {
					if_block.p(ctx, dirty);

					if (dirty & /*chunks*/ 4) {
						transition_in(if_block, 1);
					}
				} else {
					if_block = create_if_block$1(ctx);
					if_block.c();
					transition_in(if_block, 1);
					if_block.m(if_block_anchor.parentNode, if_block_anchor);
				}
			} else if (if_block) {
				group_outros();

				transition_out(if_block, 1, 1, () => {
					if_block = null;
				});

				check_outros();
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block);
			current = true;
		},
		o(local) {
			transition_out(if_block);
			current = false;
		},
		d(detaching) {
			if (detaching) {
				detach(if_block_anchor);
			}

			if (if_block) if_block.d(detaching);
		}
	};
}

function instance$1($$self, $$props, $$invalidate) {
	let $highlightedDates;
	let $_today;
	let $_events;
	let $theme;
	let $_interaction;
	let $_intlListDay;
	let $_intlListDaySide;
	let { date } = $$props;
	let { _events, _interaction, _intlListDay, _intlListDaySide, _today, highlightedDates, theme } = getContext('state');
	component_subscribe($$self, _events, value => $$invalidate(19, $_events = value));
	component_subscribe($$self, _interaction, value => $$invalidate(7, $_interaction = value));
	component_subscribe($$self, _intlListDay, value => $$invalidate(8, $_intlListDay = value));
	component_subscribe($$self, _intlListDaySide, value => $$invalidate(9, $_intlListDaySide = value));
	component_subscribe($$self, _today, value => $$invalidate(18, $_today = value));
	component_subscribe($$self, highlightedDates, value => $$invalidate(17, $highlightedDates = value));
	component_subscribe($$self, theme, value => $$invalidate(6, $theme = value));
	let el;
	let chunks;
	let isToday, highlight;
	let datetime;

	function div_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			el = $$value;
			$$invalidate(1, el);
		});
	}

	$$self.$$set = $$props => {
		if ('date' in $$props) $$invalidate(0, date = $$props.date);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*date, $_events, chunks*/ 524293) {
			{
				$$invalidate(2, chunks = []);
				let start = date;
				let end = addDay(cloneDate(date));

				for (let event of $_events) {
					if (!bgEvent(event.display) && eventIntersects(event, start, end)) {
						let chunk = createEventChunk(event, start, end);
						chunks.push(chunk);
					}
				}

				sortEventChunks(chunks);
			}
		}

		if ($$self.$$.dirty & /*date, $_today*/ 262145) {
			$$invalidate(3, isToday = datesEqual(date, $_today));
		}

		if ($$self.$$.dirty & /*$highlightedDates, date*/ 131073) {
			$$invalidate(4, highlight = $highlightedDates.some(d => datesEqual(d, date)));
		}

		if ($$self.$$.dirty & /*date*/ 1) {
			$$invalidate(5, datetime = toISOString(date, 10));
		}

		if ($$self.$$.dirty & /*el, date*/ 3) {
			// dateFromPoint
			if (el) {
				setPayload(el, () => ({
					allDay: true,
					date,
					resource: undefined,
					dayEl: el
				}));
			}
		}
	};

	return [
		date,
		el,
		chunks,
		isToday,
		highlight,
		datetime,
		$theme,
		$_interaction,
		$_intlListDay,
		$_intlListDaySide,
		_events,
		_interaction,
		_intlListDay,
		_intlListDaySide,
		_today,
		highlightedDates,
		theme,
		$highlightedDates,
		$_today,
		$_events,
		div_binding
	];
}

class Day extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance$1, create_fragment$2, safe_not_equal, { date: 0 });
	}
}

/* packages/list/src/Body.svelte generated by Svelte v4.2.18 */

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[18] = list[i];
	return child_ctx;
}

// (45:8) {:else}
function create_else_block(ctx) {
	let each_1_anchor;
	let current;
	let each_value = ensure_array_like(/*$_viewDates*/ ctx[1]);
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	const out = i => transition_out(each_blocks[i], 1, 1, () => {
		each_blocks[i] = null;
	});

	return {
		c() {
			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			each_1_anchor = empty();
		},
		m(target, anchor) {
			for (let i = 0; i < each_blocks.length; i += 1) {
				if (each_blocks[i]) {
					each_blocks[i].m(target, anchor);
				}
			}

			insert(target, each_1_anchor, anchor);
			current = true;
		},
		p(ctx, dirty) {
			if (dirty & /*$_viewDates*/ 2) {
				each_value = ensure_array_like(/*$_viewDates*/ ctx[1]);
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
						transition_in(each_blocks[i], 1);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						transition_in(each_blocks[i], 1);
						each_blocks[i].m(each_1_anchor.parentNode, each_1_anchor);
					}
				}

				group_outros();

				for (i = each_value.length; i < each_blocks.length; i += 1) {
					out(i);
				}

				check_outros();
			}
		},
		i(local) {
			if (current) return;

			for (let i = 0; i < each_value.length; i += 1) {
				transition_in(each_blocks[i]);
			}

			current = true;
		},
		o(local) {
			each_blocks = each_blocks.filter(Boolean);

			for (let i = 0; i < each_blocks.length; i += 1) {
				transition_out(each_blocks[i]);
			}

			current = false;
		},
		d(detaching) {
			if (detaching) {
				detach(each_1_anchor);
			}

			destroy_each(each_blocks, detaching);
		}
	};
}

// (41:8) {#if noEvents}
function create_if_block(ctx) {
	let div;
	let div_class_value;
	let setContent_action;
	let mounted;
	let dispose;

	return {
		c() {
			div = element("div");
			attr(div, "class", div_class_value = /*$theme*/ ctx[3].noEvents);
		},
		m(target, anchor) {
			insert(target, div, anchor);

			if (!mounted) {
				dispose = [
					action_destroyer(setContent_action = setContent.call(null, div, /*content*/ ctx[0])),
					listen(div, "click", /*handleClick*/ ctx[12])
				];

				mounted = true;
			}
		},
		p(ctx, dirty) {
			if (dirty & /*$theme*/ 8 && div_class_value !== (div_class_value = /*$theme*/ ctx[3].noEvents)) {
				attr(div, "class", div_class_value);
			}

			if (setContent_action && is_function(setContent_action.update) && dirty & /*content*/ 1) setContent_action.update.call(null, /*content*/ ctx[0]);
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) {
				detach(div);
			}

			mounted = false;
			run_all(dispose);
		}
	};
}

// (46:12) {#each $_viewDates as date}
function create_each_block(ctx) {
	let day;
	let current;
	day = new Day({ props: { date: /*date*/ ctx[18] } });

	return {
		c() {
			create_component(day.$$.fragment);
		},
		m(target, anchor) {
			mount_component(day, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const day_changes = {};
			if (dirty & /*$_viewDates*/ 2) day_changes.date = /*date*/ ctx[18];
			day.$set(day_changes);
		},
		i(local) {
			if (current) return;
			transition_in(day.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(day.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(day, detaching);
		}
	};
}

function create_fragment$1(ctx) {
	let div1;
	let div0;
	let current_block_type_index;
	let if_block;
	let div0_class_value;
	let div1_class_value;
	let current;
	const if_block_creators = [create_if_block, create_else_block];
	const if_blocks = [];

	function select_block_type(ctx, dirty) {
		if (/*noEvents*/ ctx[2]) return 0;
		return 1;
	}

	current_block_type_index = select_block_type(ctx);
	if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);

	return {
		c() {
			div1 = element("div");
			div0 = element("div");
			if_block.c();
			attr(div0, "class", div0_class_value = /*$theme*/ ctx[3].content);
			attr(div1, "class", div1_class_value = /*$theme*/ ctx[3].body);
		},
		m(target, anchor) {
			insert(target, div1, anchor);
			append(div1, div0);
			if_blocks[current_block_type_index].m(div0, null);
			/*div1_binding*/ ctx[15](div1);
			current = true;
		},
		p(ctx, [dirty]) {
			let previous_block_index = current_block_type_index;
			current_block_type_index = select_block_type(ctx);

			if (current_block_type_index === previous_block_index) {
				if_blocks[current_block_type_index].p(ctx, dirty);
			} else {
				group_outros();

				transition_out(if_blocks[previous_block_index], 1, 1, () => {
					if_blocks[previous_block_index] = null;
				});

				check_outros();
				if_block = if_blocks[current_block_type_index];

				if (!if_block) {
					if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);
					if_block.c();
				} else {
					if_block.p(ctx, dirty);
				}

				transition_in(if_block, 1);
				if_block.m(div0, null);
			}

			if (!current || dirty & /*$theme*/ 8 && div0_class_value !== (div0_class_value = /*$theme*/ ctx[3].content)) {
				attr(div0, "class", div0_class_value);
			}

			if (!current || dirty & /*$theme*/ 8 && div1_class_value !== (div1_class_value = /*$theme*/ ctx[3].body)) {
				attr(div1, "class", div1_class_value);
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block);
			current = true;
		},
		o(local) {
			transition_out(if_block);
			current = false;
		},
		d(detaching) {
			if (detaching) {
				detach(div1);
			}

			if_blocks[current_block_type_index].d();
			/*div1_binding*/ ctx[15](null);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $_view;
	let $noEventsClick;
	let $noEventsContent;
	let $_events;
	let $_viewDates;
	let $theme;
	let $_bodyEl;
	let { _bodyEl, _events, _view, _viewDates, noEventsClick, noEventsContent, theme } = getContext('state');
	component_subscribe($$self, _bodyEl, value => $$invalidate(4, $_bodyEl = value));
	component_subscribe($$self, _events, value => $$invalidate(14, $_events = value));
	component_subscribe($$self, _view, value => $$invalidate(16, $_view = value));
	component_subscribe($$self, _viewDates, value => $$invalidate(1, $_viewDates = value));
	component_subscribe($$self, noEventsClick, value => $$invalidate(17, $noEventsClick = value));
	component_subscribe($$self, noEventsContent, value => $$invalidate(13, $noEventsContent = value));
	component_subscribe($$self, theme, value => $$invalidate(3, $theme = value));
	let noEvents, content;

	function handleClick(jsEvent) {
		if (is_function($noEventsClick)) {
			$noEventsClick({
				jsEvent,
				view: toViewWithLocalDates($_view)
			});
		}
	}

	function div1_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			$_bodyEl = $$value;
			_bodyEl.set($_bodyEl);
		});
	}

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*$_viewDates, $_events*/ 16386) {
			{
				$$invalidate(2, noEvents = true);

				if ($_viewDates.length) {
					let start = $_viewDates[0];
					let end = addDay(cloneDate($_viewDates[$_viewDates.length - 1]));

					for (let event of $_events) {
						if (!bgEvent(event.display) && event.start < end && event.end > start) {
							$$invalidate(2, noEvents = false);
							break;
						}
					}
				}
			}
		}

		if ($$self.$$.dirty & /*$noEventsContent, content*/ 8193) {
			{
				$$invalidate(0, content = is_function($noEventsContent)
				? $noEventsContent()
				: $noEventsContent);

				if (typeof content === 'string') {
					$$invalidate(0, content = { html: content });
				}
			}
		}
	};

	return [
		content,
		$_viewDates,
		noEvents,
		$theme,
		$_bodyEl,
		_bodyEl,
		_events,
		_view,
		_viewDates,
		noEventsClick,
		noEventsContent,
		theme,
		handleClick,
		$noEventsContent,
		$_events,
		div1_binding
	];
}

class Body extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment$1, safe_not_equal, {});
	}
}

/* packages/list/src/View.svelte generated by Svelte v4.2.18 */

function create_fragment(ctx) {
	let body;
	let current;
	body = new Body({});

	return {
		c() {
			create_component(body.$$.fragment);
		},
		m(target, anchor) {
			mount_component(body, target, anchor);
			current = true;
		},
		p: noop,
		i(local) {
			if (current) return;
			transition_in(body.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(body.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(body, detaching);
		}
	};
}

class View extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, null, create_fragment, safe_not_equal, {});
	}
}

var index = {
	createOptions(options) {
		// Common options
		options.buttonText.listDay = 'list';
		options.buttonText.listWeek = 'list';
		options.buttonText.listMonth = 'list';
		options.buttonText.listYear = 'list';
		options.listDayFormat = {weekday: 'long'};
		options.listDaySideFormat = {year: 'numeric', month: 'long', day: 'numeric'};
		options.noEventsClick = undefined;  // ec option
		options.noEventsContent = 'No events';
		options.theme.daySide = 'ec-day-side';
		options.theme.eventTag = 'ec-event-tag';
		options.theme.noEvents = 'ec-no-events';
		options.view = 'listWeek';
		options.views.listDay = {
			buttonText: btnTextDay,
			component: View,
			duration: {days: 1},
			theme: themeView('ec-list ec-day-view')
		};
		options.views.listWeek = {
			buttonText: btnTextWeek,
			component: View,
			duration: {weeks: 1},
			theme: themeView('ec-list ec-week-view')
		};
		options.views.listMonth = {
			buttonText: btnTextMonth,
			component: View,
			duration: {months: 1},
			theme: themeView('ec-list ec-month-view')
		};
		options.views.listYear = {
			buttonText: btnTextYear,
			component: View,
			duration: {years: 1},
			theme: themeView('ec-list ec-year-view')
		};
	},

	createStores(state) {
		state._intlListDay = intl(state.locale, state.listDayFormat);
		state._intlListDaySide = intl(state.locale, state.listDaySideFormat);
	}
};

export { index as default };
